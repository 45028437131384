export default [
  {
    header: '营销管理',
    action: 'read',
    resource: 'ecom',
  },
  {
    title: '活码管理',
    icon: 'FileTextIcon',
    children: [
      {
        title: '物料管理',
        route: 'material',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '推广员管理',
        route: 'promoterlist',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '群活码',
        route: '',
        action: 'read',
        resource: 'wechat',
      },
     
    ],
  },
  {
    title: '群发任务',
    icon: 'FileTextIcon',
    children: [
      {
        title: '朋友圈',
        route: '',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '智能群发',
        route: '',
        action: 'read',
        resource: 'wechat',
      },
    ],
  },
  {
    title: '素材库',
    icon: 'FileTextIcon',
    children: [
      {
        title: '图片素材',
        route: '',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '视频素材',
        route: '',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '外部连接',
        route: '',
        action: 'read',
        resource: 'wechat',
      },
    ],
  },
  {
    title: '智能加粉',
    icon: 'FileTextIcon',
    children: [
      {
        title: '用户池',
        route: '',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '加粉日志',
        route: '',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '任务管理',
        route: 'taskmanagement',
        action: 'read',
        resource: 'wechat',
      },
    ],
  },
]
