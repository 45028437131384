export default [
  {
    header: '电商管理',
    action: 'read',
    resource: 'ecom',
  },
  {
    title: '返利管理',
    icon: 'CreditCardIcon',
    tag: '',
    tagVariant: 'light-success',
    action: 'read',
    resource: 'ecom',
    children: [
      {
        title: '群发配置',
        route: 'GroupConfig',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '产品列表',
        route: 'TbCommodity',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '淘宝返利',
        route: 'wxqrcode',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '京东返利',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '拼多多返利',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '美团返利',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '抖音返利',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '电影票返利',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      
    ],
  },
  {
    title: '财务管理',
    icon: 'TypeIcon',
    children: [
      {
        title: '资金明细',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '提现记录',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
    ],
  },
  {
    title: '自营商城',
    icon: '',
    children: [
      {
        title: '店铺管理',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '商品管理',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '库存管理',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '仓库管理',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '商品分类',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '物流管理',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
    ],
  },
]
