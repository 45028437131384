export default [
  {
    header: '运营号',
    action: 'read',
    resource: 'ecom',
  },
  {
    title: '微信号管理',
    route: 'wechatlist',
    icon: 'MailIcon',
    action: 'read',
    resource: 'wechat',
  },
  {
    title: '好友管理',
    route: 'Wxcontactinfo',
    icon: 'MessageSquareIcon',
    action: 'read',
    resource: 'wechat',
  },
  {
    title: '群聊管理',
    route: 'ChatRoomManger',
    icon: 'CheckSquareIcon',
    action: 'read',
    resource: 'wechat',
  },

  {
    title: '风控管理',
    icon: 'FileTextIcon',
    children: [
      {
        title: '风控明细',
        route: '',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '关键词管理',
        route: '',
        action: 'read',
        resource: 'wechat',
      },
    ],
  },
]
