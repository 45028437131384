export default [
  {
    title: '数据分析',
    icon: 'HomeIcon',
    tag: '2',
    action: 'read',
    resource: 'data',
    tagVariant: 'light-warning',
    children: [
      {
        title: '加粉分析',
        route: 'dashboard-ecommerce',
        action: 'read',
        resource: 'data',
      },
      {
        title: '聊天分析',
        route: 'dashboard-analytics',
        action: 'read',
        resource: 'data',
      },
    ],
  },
]
